<!-- @format -->

<template>
	<div>
		<form id="form" class="margin-top-15">
			<span class="exchange-title">All the prices are in</span>
			<span class="exchange-unit">{{ excUnit }}</span>
			<vue-form-generator
				:schema="schemaOrderLines"
				:model="modelOrderLines"
				:options="formOptionsOrderLines"
			>
			</vue-form-generator>
		</form>
		<div>
			<b-button
				class="is-success"
				type="submit"
				@click="resetPrices"
				v-if="true"
				>Reset the Prices
			</b-button>
			<b-button
				class="is-danger margin-left-20"
				type="submit"
				@click="unlockEditPrice"
				v-if="true"
				>Manually change the Price
			</b-button>
		</div>
		<div v-if="orderModalState.isAddOrEdit === 'add'">
			<b-button
				:disabled="!!toggleAddButton"
				class="is-info margin-top-25"
				type="submit"
				@click="onSubmit"
				v-if="true"
				>Add Order Item
			</b-button>
		</div>
		<div v-if="orderModalState.isAddOrEdit === 'edit'">
			<b-button
				:disabled="!!toggleAddButton"
				class="is-info margin-top-25"
				type="submit"
				@click="onUpdateOrder"
				v-if="true"
				>Add Order Item & Update Order
			</b-button>
		</div>
	</div>
</template>

<script>
// Cleave
// Cleave
// eslint-disable-next-line no-unused-vars
import cleave from 'cleave.js'
// import dateformat from 'dateformat'
import {
	computed,
	onMounted,
	reactive,
	ref,
	// watchEffect,
} from '@vue/composition-api'
// import Store from '@/store'
import { useQuery, useMutation } from '@vue/apollo-composable'
import GetStockItemPricesQuery from '@/_srcv2/pages/order/add-order/graphql/GetStockItemPricesQuery.graphql'
import InsertOrderLineMutation from '@/_srcv2/pages/order/add-order/subcomponents/add-order-lines/graphql/InsertOrderLineMutation.graphql'
import useReportNumber from '@/_srcv2/views/_shared/_composables/format-report-numbers/useReportNumber'
import useOrderModalState from '@/_srcv2/pages/order/add-order/subcomponents/add-order-lines/useOrderModalState.js'
import useGetUuid from '@/_srcv2/views/_shared/_composables/create-uuid/useGetUuid'
import storeOrderHeaders from '@/_srcv2/pages/order/add-order/subcomponents/get-order-headers/useOrderHeaderState.js'
import storeCurrentOrder from '@/_srcv2/pages/order/add-order/subcomponents/current-order/useCurrentOrderState.js'
import storeOrder from '@/_srcv2/pages/order/_shared/useOrderState.js'
import numeral from 'numeral'

export default {
	name: 'AddOrderLinesModal',
	setup() {
		const { orderModalState } = useOrderModalState()
		// --------------------------------------------------------------------------------
		const {
			intToFloatCleave,
			// intToFloatReport,
			cleaveToFloat,
			cleaveToInt100,
			floatToInt100,
		} = useReportNumber()
		// --------------------------------------------------------------------------------
		const { modelOrder } = storeOrderHeaders()
		const excUnit = computed(() => modelOrder.orderExchangeUnit)
		// --------------------------------------------------------------------------------
		const { getUUID } = useGetUuid()
		const getFormattedPrice = (rawPrice) => {
			const type = computed(() => modelOrder.orderType)
			if (type.value === 'Inland') {
				return intToFloatCleave(rawPrice)
			} else {
				return intToFloatCleave(
					rawPrice * cleaveToFloat(modelOrder.orderExchangeRate),
				)
			}
		}
		// --------------------------------------------------------------------------------
		onMounted(() => {
			modelOrderLines.transactionId = getUUID()
			modelOrderLines.transactionType = 'customer order'
			modelOrderLines.stockId = orderModalState.stockId
			modelOrderLines.lineInfo = orderModalState.lineInfo
			modelOrderLines.normalPrice = getFormattedPrice(
				orderModalState.normalPrice,
			)
			modelOrderLines.campaignPrice = orderModalState.campaignPrice
			modelOrderLines.customerSpecialPrice =
				orderModalState.customerSpecialPrice
			modelOrderLines.customerDiscountedPrice =
				orderModalState.customerDiscountedPrice
			modelOrderLines.customerInvoicePrice =
				orderModalState.customerInvoicePrice
			modelOrderLines.customerInvoiceExchangePrice =
				orderModalState.customerInvoiceExchangePrice
			modelOrderLines.vatPercent =
				modelOrder.orderType !== 'Inland' ? 0 : orderModalState.vatPercent
			modelOrderLines.amountCredit = orderModalState.amountCredit
		})
		// --------------------------------------------------------------------------------
		console.log('AddOrderLinesModal onCreated is fired')
		const modelOrderLines = reactive({
			transactionId: '',
			transactionType: 'customer order',
			stockId: '',
			lineInfo: '',
			normalPrice: '',
			campaignPrice: '',
			customerSpecialPrice: '',
			customerDiscountedPrice: '',
			customerInvoicePrice: '',
			customerInvoiceExchangePrice: '',
			vatPercent: '',
			amountCredit: 'enter amount',
			// -----------------------------------------------
			goodsTransactionsInvoicePrice: 0,
			goodsTransactionsInvoicePriceExchange: 0,
		})
		const schemaOrderLines = reactive({
			groups: [
				{
					legend: 'Stock Item to add to Order',
					id: 'orderLines',
					featured: true,
					fields: [
						{
							type: 'input',
							inputType: 'text',
							label:
								'Order info (stock id - item name - unit) You can edit if you need',
							model: 'lineInfo',
							placeholder: 'Please enter Information',
							readonly: false,
							featured: true,
							disabled: false,
							required: true,
							validator: ['string'],
							onValidated: function (model, errors) {
								console.log('*** validated', model)
								if (errors.length > 0) {
									toggleAddButton.value = true
									console.log(
										'Validation error in Information field! Errors:',
										errors,
									)
								} else {
									const checkAmountCredit =
										model.amountCredit === null ||
										model.amountCredit === undefined ||
										model.amountCredit === ''
									const checkCustomerInvoicePrice =
										model.customerInvoicePrice === null ||
										model.customerInvoicePrice === undefined ||
										model.customerInvoicePrice === ''
									const isDisabled =
										checkAmountCredit || checkCustomerInvoicePrice
									console.log('--- checkAmountCredit', checkAmountCredit)
									console.log(
										'--- checkCustomerInvoicePrice',
										checkCustomerInvoicePrice,
									)
									console.log('--- isDisabled', isDisabled)
									console.log('no errors, isDisabled: ', isDisabled)
									if (isDisabled) {
										toggleAddButton.value = true
									} else {
										toggleAddButton.value = false
									}
								}
							},
						},
						// -----------------------------------------------------------------------------
						{
							type: 'cleave',
							label: `Item List Price in ${excUnit.value}`,
							model: 'normalPrice',
							placeholder: 'Please get stock item normal price',
							featured: true,
							disabled: true,
							readonly: true,
							required: true,
							validator: [],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Price field! Errors:',
										errors,
									)
							},
						},
						{
							type: 'cleave',
							label: `Item Campaign Price in ${excUnit.value}`,
							model: 'campaignPrice',
							placeholder: 'Please get stock item campaign price',
							featured: true,
							disabled: true,
							readonly: true,
							required: true,
							validator: [],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Price field! Errors:',
										errors,
									)
							},
						},
						{
							type: 'cleave',
							label: `Item Customer Special Price in ${excUnit.value}`,
							model: 'customerSpecialPrice',
							placeholder: 'Please get stock item normal price',
							featured: true,
							disabled: true,
							readonly: true,
							required: true,
							validator: [],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Price field! Errors:',
										errors,
									)
							},
						},
						{
							type: 'cleave',
							label: `Item Discounted Price in ${excUnit.value} (depends on customer class: a,b,c,d,s,hra,hrb,hrc,hrd,z)`,
							model: 'customerDiscountedPrice',
							placeholder: 'Please get stock item discounted price',
							featured: true,
							disabled: true,
							readonly: true,
							required: true,
							validator: [],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0)
									console.log(
										'Validation error in Price field! Errors:',
										errors,
									)
							},
						},
						{
							// Only appear if packet value is true
							visible(model) {
								return model && modelOrder.orderType === 'Inland'
							},
							type: 'cleave',
							label: `Item Invoice Price in ${excUnit.value}`,
							model: 'customerInvoicePrice',
							placeholder: 'Please get stock item sale-invoice price',
							featured: true,
							disabled: true,
							readonly: false,
							required: true,
							validator: ['notnegativenumber', 'required'],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								console.log('onValidated', model)
								if (errors.length > 0) {
									toggleAddButton.value = true
									console.log(
										'Validation error in Price field! Errors:',
										errors,
									)
								} else {
									console.log(
										'----------------------------------------------------',
									)
									model.goodsTransactionsInvoicePrice = cleaveToFloat(
										model.customerInvoicePrice,
									)
									model.goodsTransactionsInvoicePriceExchange = cleaveToFloat(
										model.customerInvoicePrice,
									)
									model.customerInvoiceExchangePrice = cleaveToFloat(
										model.customerInvoicePrice,
									)
									// ** --------------------------------------------------------------
									const checkAmountCredit =
										model.amountCredit === null ||
										model.amountCredit === undefined ||
										model.amountCredit === ''
									const checkCustomerInvoicePrice =
										model.customerInvoicePrice === null ||
										model.customerInvoicePrice === undefined ||
										model.customerInvoicePrice === ''
									const isDisabled =
										checkAmountCredit || checkCustomerInvoicePrice
									console.log('--- checkAmountCredit', checkAmountCredit)
									console.log(
										'--- checkCustomerInvoicePrice',
										checkCustomerInvoicePrice,
									)
									console.log('--- isDisabled', isDisabled)
									console.log('no errors, isDisabled: ', isDisabled)
									if (isDisabled) {
										toggleAddButton.value = true
									} else {
										toggleAddButton.value = false
									}
								}
							},
						},
						{
							// Only appear if packet value is true
							visible(model) {
								return model && modelOrder.orderType !== 'Inland'
								// model && model.showExchangeFieldsOnForm === true
							},
							type: 'cleave',
							label: `Item Invoice Exchange Price in ${excUnit.value}`,
							model: 'customerInvoiceExchangePrice',
							placeholder: 'Please get customer sale-invoice exchange price',
							featured: true,
							disabled: true,
							readonly: false,
							required: true,
							validator: ['notnegativenumber', 'required'],
							cleaveOptions: {
								numeral: true,
								numeralThousandsGroupStyle: 'thousand',
								numeralDecimalScale: 2,
								numeralDecimalMark: ',',
							},
							onValidated: function (model, errors) {
								if (errors.length > 0) {
									toggleAddButton.value = true
									console.log(
										'Validation error in Price field! Errors:',
										errors,
									)
								} else {
									const exc =
										cleaveToFloat(modelOrder.orderExchangeRate) * 10000
									// console.log('=== exc', exc)
									// console.log('=== typeof exc', typeof exc)
									// console.log(
									//   '=== model.goodsTransactionsInvoicePrice',
									//   model.goodsTransactionsInvoicePrice
									// )
									model.goodsTransactionsInvoicePriceExchange = cleaveToFloat(
										model.customerInvoiceExchangePrice,
									)
									console.log(
										'=== model.goodsTransactionsInvoicePriceExchange',
										model.goodsTransactionsInvoicePriceExchange,
									)
									model.goodsTransactionsInvoicePrice = (
										(cleaveToFloat(model.customerInvoiceExchangePrice) / exc) *
										10000
									).toFixed(2)
									console.log(
										'=== model.goodsTransactionsInvoicePrice',
										model.goodsTransactionsInvoicePrice,
									)
									// ** ------------------------------------------------------------
									const checkAmountCredit =
										model.amountCredit === null ||
										model.amountCredit === undefined ||
										model.amountCredit === ''
									const checkCustomerInvoicePrice =
										model.customerInvoicePrice === null ||
										model.customerInvoicePrice === undefined ||
										model.customerInvoicePrice === ''
									const isDisabled =
										checkAmountCredit || checkCustomerInvoicePrice
									console.log('--- checkAmountCredit', checkAmountCredit)
									console.log(
										'--- checkCustomerInvoicePrice',
										checkCustomerInvoicePrice,
									)
									console.log('--- isDisabled', isDisabled)
									console.log('no errors, isDisabled: ', isDisabled)
									if (isDisabled) {
										toggleAddButton.value = true
									} else {
										toggleAddButton.value = false
									}
								}
							},
						},
						//------------------------------------------------------------------------------
						{
							type: 'input',
							inputType: 'number',
							label: 'Order Amount',
							model: 'amountCredit',
							placeholder: 'Please enter Order Amount',
							readonly: false,
							featured: true,
							disabled: false,
							required: true,
							validator: [
								'required',
								'integer',
								'notzero',
								'notnull',
								'notempty',
							],
							onValidated: function (model, errors) {
								console.log('*** validated', model)
								if (errors.length > 0) {
									toggleAddButton.value = true
									console.log(
										'Validation error in Information field! Errors:',
										errors,
									)
								} else {
									const checkAmountCredit =
										model.amountCredit === null ||
										model.amountCredit === undefined ||
										model.amountCredit === ''
									const checkCustomerInvoicePrice =
										model.customerInvoicePrice === null ||
										model.customerInvoicePrice === undefined ||
										model.customerInvoicePrice === ''
									const isDisabled =
										checkAmountCredit || checkCustomerInvoicePrice
									console.log('--- checkAmountCredit', checkAmountCredit)
									console.log(
										'--- checkCustomerInvoicePrice',
										checkCustomerInvoicePrice,
									)
									console.log('--- isDisabled', isDisabled)
									console.log('no errors, isDisabled: ', isDisabled)
									if (isDisabled) {
										toggleAddButton.value = true
									} else {
										toggleAddButton.value = false
									}
								}
							},
						},
					],
				},
			],
		})
		const formOptionsOrderLines = reactive({
			validateAfterLoad: false,
			validateAfterChanged: true,
			validateAsync: true,
			fieldIdPrefix: 'orderLines',
		})
		// ---------------------------------------------------------------
		const toggleAddButton = ref(true)
		// const onFormValidated = (isValid) => {
		// 	if (isValid) {
		// 		if (modelOrderLines.amountCredit === null) {
		// 			toggleAddButton.value = true
		// 		} else {
		// 			toggleAddButton.value = false
		// 		}
		// 		toggleAddButton.value = false
		// 	}
		// }
		// *********************************************************************
		// Get All Prices for stock item
		const options = reactive({
			fetchPolicy: 'network-only',
		})
		const {
			result: resultPrices,
			onResult: onResultPrices,
			// refetch: refetchPrices,
		} = useQuery(
			GetStockItemPricesQuery,
			() => ({
				company_id: orderModalState.ourCompany,
				customer_id: orderModalState.customerId,
				stock_id: orderModalState.stockId,
			}),
			options,
		)
		onResultPrices(() => {
			getPrices()
		})

		// watchEffect(() => {
		// 	if (modelOrderLines.amountCredit.length < 1) {
		// 		toggleAddButton.value = true
		// 	}
		// })
		// ----------------------------------------------------------------------------------------------
		const getInvoicePrice = (object, type) => {
			console.log('getInvoicePrice object', object)
			const ordersType = computed(() => modelOrder.orderType)
			const exc = computed(() => modelOrder.orderExchangeRate)
			if (type === 'invoicePrice' && ordersType === 'Inland') {
				return getCustomerInvoicePrice(object)
			} else if (type === 'invoicePrice' && ordersType !== 'Inland') {
				console.log('exc', exc)
				console.log('typeof exc', typeof exc)
				console.log(
					'getCustomerInvoicePrice(object) / parseFloat(exc)',
					getCustomerInvoicePrice(object) * cleaveToFloat(exc.value),
				)
				return getCustomerInvoicePrice(object) / cleaveToFloat(exc.value)
			} else if (type === 'invoiceExchangePrice') {
				return getCustomerInvoicePrice(object)
			} else {
				return 0
			}
		}
		// ----------------------------------------------------------------------------------------------
		let prices = ref([])
		const getCustomerInvoicePrice = (obj) => {
			if (obj.rawNormalPrice > 0) {
				prices.value.push(obj.rawNormalPrice)
			}
			if (obj.rawCampaignPrice > 0) {
				prices.value.push(obj.rawCampaignPrice)
			}
			if (obj.rawSpecialPrice > 0) {
				prices.value.push(obj.rawSpecialPrice)
			}
			if (obj.customerPriceClass !== null || undefined) {
				prices.value.push(getCustomerDiscountedPrice(obj))
			}
			return Math.min(...prices.value)
		}
		// ------------------------------------------------------------------------------------
		const getCustomerDiscountedPrice = (obj) => {
			if (obj.customerPriceClass !== null || undefined) {
				if (obj.customerPriceClass === 'a') {
					return obj.aPrice
				} else if (obj.customerPriceClass === 'b') {
					return obj.bPrice
				} else if (obj.customerPriceClass === 'c') {
					return obj.cPrice
				} else if (obj.customerPriceClass === 'd') {
					return obj.dPrice
				} else if (obj.customerPriceClass === 's') {
					return obj.sPrice
				} else if (obj.customerPriceClass === 'hra') {
					return obj.hraPrice
				} else if (obj.customerPriceClass === 'hrb') {
					return obj.hrbPrice
				} else if (obj.customerPriceClass === 'hrc') {
					return obj.hrcPrice
				} else if (obj.customerPriceClass === 'hrd') {
					return obj.hrdPrice
				} else if (obj.customerPriceClass === 'z') {
					return obj.zPrice
				}
			}
			return 0
		}
		const fetchPrices = (result) => {
			return new Promise((resolve, reject) => {
				if (result !== null || undefined) {
					const customerPrices = {
						rawNormalPrice: result?.stock[0]?.stock_price ?? 0,
						rawCampaignPrice: result?.stock[0]?.campaign_price ?? 0,
						rawSpecialPrice:
							result?.customer_price_list[0]?.customers_price ?? 0,
						aPrice: result?.stock[0]?.stock_price_a ?? 0,
						bPrice: result?.stock[0]?.stock_price_b ?? 0,
						cPrice: result?.stock[0]?.stock_price_c ?? 0,
						dPrice: result?.stock[0]?.stock_price_d ?? 0,
						sPrice: result?.stock[0]?.stock_price_s ?? 0,
						hraPrice: result?.stock[0]?.stock_price_hra ?? 0,
						hrbPrice: result?.stock[0]?.stock_price_hrb ?? 0,
						hrcPrice: result?.stock[0]?.stock_price_hrc ?? 0,
						hrdPrice: result?.stock[0]?.stock_price_hrd ?? 0,
						zPrice: result?.stock[0]?.stock_price_z ?? 0,
						customerPriceClass:
							result?.customers[0]?.customer_price_class ?? 'd',
					}
					console.log('-*-*-*-*- customerPrices fetchPrices', customerPrices)
					resolve(customerPrices)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const formatPrices = (obj) => {
			return new Promise((resolve, reject) => {
				if (obj !== null || undefined) {
					const customerFormattedPrices = {
						normalPrice: getFormattedPrice(obj.rawNormalPrice),
						campaignPrice: getFormattedPrice(obj.rawCampaignPrice),
						specialPrice: getFormattedPrice(obj.rawSpecialPrice),
						discountedPrice: getFormattedPrice(getCustomerDiscountedPrice(obj)),
						invoicePrice: getFormattedPrice(
							getInvoicePrice(obj, 'invoicePrice'),
						),
						invoiceExchangePrice: getFormattedPrice(
							getInvoicePrice(obj, 'invoiceExchangePrice'),
						),
					}
					console.log('***customerFormattedPrices', customerFormattedPrices)
					resolve(customerFormattedPrices)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const setPrices = (obj) => {
			console.log('setPrices obj', obj)
			return new Promise((resolve, reject) => {
				if (obj !== null || undefined) {
					modelOrderLines.normalPrice = obj.normalPrice
					modelOrderLines.campaignPrice = obj.campaignPrice
					modelOrderLines.customerSpecialPrice = obj.specialPrice
					modelOrderLines.customerDiscountedPrice = obj.discountedPrice
					modelOrderLines.customerInvoicePrice = obj.invoicePrice
					modelOrderLines.customerInvoiceExchangePrice =
						obj.invoiceExchangePrice
					console.log('setPrices modelOrderLines', modelOrderLines)
					resolve(obj)
				} else {
					const reason = new Error('Data could not be fetched from database')
					reject(reason)
				}
			})
		}
		const getPrices = () => {
			console.log('getPrices is fired')
			fetchPrices(resultPrices.value)
				.then((obj) => formatPrices(obj))
				.then((obj) => setPrices(obj))
				.then(() => {
					console.log(
						'record modelOrder.orderExchangeRate',
						modelOrder.orderExchangeRate,
					)
					console.log(
						'typeof modelOrder.orderExchangeRate',
						typeof modelOrder.orderExchangeRate,
					)
					if (modelOrder.orderType === 'Inland') {
						modelOrderLines.goodsTransactionsInvoicePrice = cleaveToFloat(
							modelOrderLines.customerInvoicePrice,
						)
						modelOrderLines.goodsTransactionsInvoicePriceExchange =
							cleaveToFloat(modelOrderLines.customerInvoicePrice)
					} else {
						modelOrderLines.goodsTransactionsInvoicePriceExchange =
							cleaveToFloat(modelOrderLines.customerInvoiceExchangePrice)
						modelOrderLines.goodsTransactionsInvoicePrice =
							cleaveToFloat(modelOrderLines.customerInvoiceExchangePrice) /
							cleaveToFloat(modelOrder.orderExchangeRate)
					}
				})
		}
		// ----------------------------------------------------------------------
		// Format values
		numeral.defaultFormat('0,0.00')
		// Add the new order line to array
		const getRecord = () => {
			console.log('getRecord modelOrderLines', modelOrderLines)
			return new Promise((resolve) => {
				const record = {
					goods_transaction_id: modelOrderLines.transactionId,
					transaction_type: modelOrderLines.transactionType,
					stock_id: modelOrderLines.stockId,
					line_info: modelOrderLines.lineInfo,
					amount_credit: modelOrderLines.amountCredit,
					order_amount: modelOrderLines.amountCredit,
					dispatch_amount: modelOrderLines.amountCredit,
					unit_price: cleaveToInt100(modelOrderLines.normalPrice),
					vat_percent: modelOrderLines.vatPercent,
					vat_credit: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePrice *
							(modelOrderLines.vatPercent / 100) *
							modelOrderLines.amountCredit,
					),
					invoice_price: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePrice,
					),
					line_price_total_credit: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePrice *
							modelOrderLines.amountCredit,
					),
					vat_credit_exchange: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePriceExchange *
							(modelOrderLines.vatPercent / 100) *
							modelOrderLines.amountCredit,
					),
					invoice_price_exchange: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePriceExchange,
					),
					line_price_total_credit_exchange: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePriceExchange *
							modelOrderLines.amountCredit,
					),
				}
				console.log('record', record)
				resolve(record)
			})
		}
		// Push new order line
		const { orderLinesArray } = storeCurrentOrder()
		const onSubmit = () => {
			// push to array
			getRecord()
				.then((record) => {
					console.log('getRecord record', record)
					orderLinesArray.value.push(record)
				})
				.then(() => {
					orderModalState.isModalVisible = false
				})
		}
		// -------------------------------------------------------------
		const mutationVariable = ref([])
		const { mutate: insertOrderLine } = useMutation(
			InsertOrderLineMutation,
			() => ({
				variables: {
					input: mutationVariable.value,
				},
			}),
		)
		//----------------------------------------------------------------------
		const { reportOrderNumber } = storeOrder()
		const getOrderLine = () => {
			console.log('getOrderLine modelOrderLines', modelOrderLines)
			return new Promise((resolve) => {
				const line = {
					goods_transaction_id: modelOrderLines.transactionId,
					order_number: reportOrderNumber.value,
					transaction_type: modelOrderLines.transactionType,
					stock_id: modelOrderLines.stockId,
					line_info: modelOrderLines.lineInfo,
					amount_credit: modelOrderLines.amountCredit,
					order_amount: modelOrderLines.amountCredit,
					dispatch_amount: modelOrderLines.amountCredit,
					unit_price: cleaveToInt100(modelOrderLines.normalPrice),
					vat_percent: modelOrderLines.vatPercent,
					vat_credit: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePrice *
							(modelOrderLines.vatPercent / 100) *
							modelOrderLines.amountCredit,
					),
					invoice_price: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePrice,
					),
					line_price_total_credit: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePrice *
							modelOrderLines.amountCredit,
					),
					vat_credit_exchange: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePriceExchange *
							(modelOrderLines.vatPercent / 100) *
							modelOrderLines.amountCredit,
					),
					invoice_price_exchange: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePriceExchange,
					),
					line_price_total_credit_exchange: floatToInt100(
						modelOrderLines.goodsTransactionsInvoicePriceExchange *
							modelOrderLines.amountCredit,
					),
				}
				console.log('line', line)
				resolve(line)
			})
		}
		const onUpdateOrder = () => {
			getOrderLine()
				.then((line) => {
					mutationVariable.value.push(line)
				})
				.then(() => {
					console.log('mutationVariable', mutationVariable)
					insertOrderLine()
					orderModalState.isModalVisible = false
				})
		}
		const resetPrices = () => {
			console.log('refresh is fired')
			getPrices()
		}
		const unlockEditPrice = () => {
			let pwd = prompt('Type password to edit the price')
			if (pwd === '1994') {
				console.log('------ modelOrderLines', modelOrderLines)
				console.log('------ schemaOrderLines', schemaOrderLines)
				schemaOrderLines.groups[0].fields[5].disabled = false
				schemaOrderLines.groups[0].fields[6].disabled = false
			} else
				alert(`Password does not match
	You don't have permission
	to make any change in price
	contact with administrator`)
		}
		return {
			schemaOrderLines,
			modelOrderLines,
			formOptionsOrderLines,
			onSubmit,
			// onFormValidated,
			toggleAddButton,
			getPrices,
			orderModalState,
			onUpdateOrder,
			excUnit,
			resetPrices,
			unlockEditPrice,
		}
	},
}
</script>
<style scoped>
.margin-top-25 {
	margin-top: 25px;
}
.margin-top-15 {
	margin-top: 15px;
}
.exchange-title {
	font-weight: bold;
	font-size: 1.5em;
	color: red;
}
.exchange-unit {
	font-weight: bold;
	font-size: 1.5em;
	color: blue;
	padding-left: 15px;
}
</style>
