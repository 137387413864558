import { getCurrentInstance } from '@vue/composition-api'

export default function useGetUuid() {
	// Create UUID
	const vm = getCurrentInstance()
	const NAMESPACE = '96e1b132-5765-5e15-b9a8-8a6cec456dda'
	function getUUID() {
		let TIMEPARAMETER = (
			Date.now() + Math.floor(Math.random() * 1000000000000000000).toString()
		).toString()
		return vm.$uuid.v5(TIMEPARAMETER, NAMESPACE)
	}
	return { getUUID }
}
