// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const orderModalState = reactive({
	isModalVisible: false,
	ourCompany: '',
	customerId: '',
	stockId: '',
	lineInfo: '',
	normalPrice: 0,
	campaignPrice: 0,
	customerSpecialPrice: 0,
	customerDiscountedPrice: 0,
	customerInvoicePrice: 0,
	customerInvoiceExchangePrice: 0,
	vatPercent: 0,
	amountCredit: null,
	keyModal: 1,
	isAddOrEdit: '',
})
export default function useOrderModalState() {
	return {
		...toRefs(orderModalState),
		orderModalState,
	}
}
