<!-- @format -->

<template>
	<div>
		<div
			id="tableTitle"
			style="
				margin-top: 30px;
				background-color: rgb(76, 92, 122);
				padding: 20px;
			"
		>
			<div style="color: white; font-weight: 900">
				Search stock items - Stock items to be added to order
			</div>
		</div>
		<div v-if="loadingStockItems" class="loading-tag">Loading...</div>
		<div v-if="toggleTableConsumption">
			<div style="margin-top: -10px">
				<ve-table
					:rows="listArr"
					:columns="columnsList"
					:onCellClick="onCellClick"
					:onRowDoubleClick="onRowDoubleClick"
					:perPage="perPageProp"
					maxHeight="350px"
				>
				</ve-table>
			</div>
		</div>
	</div>
</template>

<script>
import { computed, onMounted, reactive, ref } from '@vue/composition-api'
import { useQuery } from '@vue/apollo-composable'
import storeCustomerConsumption from '@/_srcv2/pages/order/add-order/subcomponents/time-interval-customer-consumption/useCustomerConsumtionState.js'
import storeOrderHeaders from '@/_srcv2/pages/order/add-order/subcomponents/get-order-headers/useOrderHeaderState.js'
import useOrderModalState from '@/_srcv2/pages/order/add-order/subcomponents/add-order-lines/useOrderModalState.js'

import SearchStockItemQuery from '@/_srcv2/pages/order/graphql/SearchStockItemQuery.graphql'
export default {
	name: 'SearchStockItemToEditOrder',
	setup() {
		const perPageProp = ref(10)
		const { ourCompanyOrder, customerIdOrder } = storeOrderHeaders()
		const labelPosition = ref('on-border')
		const ourCompany = computed(() => ourCompanyOrder.value)
		// ------------------------------------------------------------
		// Time Interval Dispatches
		const options = reactive({
			fetchPolicy: 'no-cache',
		})
		const { refetch: refetchStockItems, loading: loadingStockItems } = useQuery(
			SearchStockItemQuery,
			() => ({
				company_id: ourCompany.value,
			}),
			options,
		)
		// Create Table
		const tableValues = ref([])
		const getData = () => {
			tableValues.value = []
			refetchStockItems().then((result) => {
				tableValues.value = result.data.stock.map((item) => {
					return {
						stockId: item.stock_id,
						itemName: item.stock_name,
						amount:
							item.outstanding_balance + item.debit_amount - item.credit_amount,
						stockUnit: item.stock_unit,
						stockNormalPrice: item.stock_price,
						stockItemVatPercent: item.stock_moms,
					}
				})
			})
			console.log('listArr', listArr)
		}
		const listArr = computed(() => tableValues.value)
		// -------------------------------------------------------------------------
		const { orderModalState } = useOrderModalState()
		const setOrderModalState = (item) => {
			console.log('selected stock item', item)
			orderModalState.ourCompany = ourCompanyOrder
			orderModalState.customerId = customerIdOrder
			orderModalState.stockId = item.stockId
			orderModalState.lineInfo = `${item.stockId} - ${item.itemName} - ${item.stockUnit}`
			orderModalState.normalPrice = item.stockNormalPrice
			orderModalState.vatPercent = item.stockItemVatPercent
			orderModalState.isModalVisible = true
			orderModalState.isAddOrEdit = 'edit'
			orderModalState.keyModal = orderModalState.keyModal + 1
			console.log('orderModalState', orderModalState)
		}
		const onCellClick = (params) => {
			if (params.column.field === 'select') {
				const item = params.row
				setOrderModalState(item)
			}
		}
		const onRowDoubleClick = (params) => {
			const item = params.row
			setOrderModalState(item)
		}
		// -------------------------------------------------------------------------
		const { columnsList } = storeCustomerConsumption()
		// -------------------------------------------------------------------------
		onMounted(() => {
			tableValues.value = []
			getData()
		})
		const toggleTableConsumption = ref(true)
		const toggle = () => {
			toggleTableConsumption.value = !toggleTableConsumption.value
		}
		return {
			perPageProp,
			labelPosition,
			getData,
			listArr,
			columnsList,
			loadingStockItems,
			onCellClick,
			onRowDoubleClick,
			toggleTableConsumption,
			toggle,
		}
	},
}
</script>

<style scoped>
.loading-tag {
	font-size: 2em;
	font-weight: bolder;
	color: #9b1c1c;
}
</style>
