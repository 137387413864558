<template>
	<div style="margin-left: 20px">
		<div class="text-bold text-green">Order Headers</div>
		<span class="text-bold text-blue"> Our Company: </span>
		{{ ourCompanyOrder }}<br />
		<span class="text-bold text-blue">Customer Id:</span>
		{{ customerIdOrder }}<br />
		<span class="text-bold text-blue">Customer Title:</span>
		{{ customerTitleOrder }}<br />
		<span class="text-bold text-blue">Customer Nickname:</span>
		{{ customerNicknameOrder }}<br />
		<span class="text-bold text-blue">Customer Reference:</span>
		{{ customerReferenceOrder }}<br />
		<span class="text-bold text-blue">Customer Email:</span>
		{{ customerEmailOrder }}<br />
		<span class="text-bold text-blue">Customer Org.Num.:</span>
		{{ customerOrgNumOrder }}<br />
	</div>
</template>

<script>
import storeOrderHeaders from '@/_srcv2/pages/order/add-order/subcomponents/get-order-headers/useOrderHeaderState'
export default {
	name: 'GetOrderHeadersEditOrder',
	setup() {
		const {
			ourCompanyOrder,
			customerIdOrder,
			customerTitleOrder,
			customerNicknameOrder,
			customerReferenceOrder,
			customerEmailOrder,
			customerOrgNumOrder,
			orderIssueDate,
			dispatchAddressId,
			invoiceAddressId,
			// customerAddressArray,
			// schemaOrder,
			// isDatePickerDisabled,
		} = storeOrderHeaders()
		return {
			ourCompanyOrder,
			customerIdOrder,
			customerTitleOrder,
			customerNicknameOrder,
			customerReferenceOrder,
			customerEmailOrder,
			customerOrgNumOrder,
			orderIssueDate,
			dispatchAddressId,
			invoiceAddressId,
		}
	},
}
</script>

<style scoped></style>
