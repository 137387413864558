<!-- @format -->

<template>
	<layout-default-new>
		<div>
			<sub-navbar page="Order" />
		</div>
		<section class="hero is-danger margin-bottom-20">
			<div class="hero-body">
				<p class="title">Edit order</p>
				<p class="subtitle">Please select the stock items to add to order</p>
			</div>
		</section>
		<div>
			<get-order-headers-edit-order />
		</div>
		<div>
			<get-order-lines />
		</div>
		<div>
			<search-stock-item-to-edit-order />
		</div>
		<div>
			<update-order />
		</div>
		<div class="max-width-150">
			<add-order-lines-modal-frame
				v-if="isModalVisible"
				@close="closeModal"
				:key="keyModal"
			/>
		</div>
	</layout-default-new>
</template>

<script>
import SubNavbar from '@/_srcv2/components/_shared/sub-navbar/SubNavbar'
import GetOrderLines from '@/_srcv2/pages/order/edit-order/subcomponents/current-order/GetOrderLines.vue'

import SearchStockItemToEditOrder from '@/_srcv2/pages/order/edit-order/subcomponents/search-stock-item/SearchStockItemToEditOrder.vue'

import GetOrderHeadersEditOrder from '@/_srcv2/pages/order/edit-order/subcomponents/get-order-headers/GetOrderHeadersEditOrder.vue'
import AddOrderLinesModalFrame from '@/_srcv2/pages/order/add-order/subcomponents/add-order-lines/AddOrderLinesModalFrame.vue'
import UpdateOrder from '@/_srcv2/pages/order/edit-order/subcomponents/update-order/UpdateOrder.vue'
import useOrderModalState from '@/_srcv2/pages/order/add-order/subcomponents/add-order-lines/useOrderModalState.js'

export default {
	name: 'EditOrder',
	components: {
		SubNavbar,
		GetOrderHeadersEditOrder,
		GetOrderLines,
		SearchStockItemToEditOrder,
		AddOrderLinesModalFrame,
		UpdateOrder,
	},
	setup() {
		const { isModalVisible, keyModal } = useOrderModalState()
		const closeModal = () => (isModalVisible.value = false)
		return {
			isModalVisible,
			keyModal,
			closeModal,
		}
	},
}
</script>

<style scoped></style>
