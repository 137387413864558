// To share the state between components write thw following three lines
// Otherwise each component creates a new instance of the reactive state object that can not sher the state
import Vue from 'vue'
import VueCompositionApi, { reactive, toRefs } from '@vue/composition-api'
Vue.use(VueCompositionApi)

const customerConsumptionState = reactive({
	columnsList: [
		{
			label: 'Select',
			field: 'select',
			width: '35px',
			sortable: false,
		},
		{
			label: 'Stock Id',
			field: 'stockId',
			width: '135px',
		},
		{
			label: 'Item Name',
			field: 'itemName',
			width: '350px',
		},
		{
			label: 'Amount',
			field: 'amount',
			width: '60px',
		},
	],
})

export default function storeCustomerConsumption() {
	return {
		...toRefs(customerConsumptionState),
	}
}
