<!-- @format -->

<template>
	<div>
		<div>
			<div
				id="tableTitle"
				style="
					margin-top: 30px;
					background-color: rgb(76, 92, 122);
					padding: 20px;
				"
			>
				<div style="color: white; font-weight: 900">
					Current Order - Stock items added to order
				</div>
			</div>
			<div style="margin-top: -10px">
				<div>
					<ve-table
						:rows="orderLinesArray"
						:columns="columnsList"
						:onCellClick="onCellClick"
						:onRowDoubleClick="onRowDoubleClick"
						:searchedOptions="false"
						:perPage="perPageProp"
						maxHeight="1500px"
					>
					</ve-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { useMutation, useResult, useSubscription } from '@vue/apollo-composable'
import SubscribeOrderLinesSubscription from '@/_srcv2/pages/order/edit-order/graphql/SubscribeOrderLinesSubscription.graphql'
import DeleteOrderByOrderNumberMutation from '@/_srcv2/pages/order/edit-order/graphql/DeleteOrderByOrderNumberMutation.graphql'
import storeOrder from '@/_srcv2/pages/order/_shared/useOrderState.js'
import { ref } from '@vue/composition-api'

export default {
	name: 'GetOrderLines',
	setup() {
		const perPageProp = ref(25)
		const columnsList = [
			{
				label: 'Delete',
				field: 'delete',
				width: '35px',
				sortable: false,
			},
			{
				label: 'Line info',
				field: 'line_info',
				width: '300px',
			},
			{
				label: 'Line Amount',
				field: 'amount_credit',
				width: '60px',
			},
			{
				label: 'Customer Price',
				field: 'invoice_price_exchange',
				width: '70px',
			},
			{
				label: 'Line Total',
				field: 'line_price_total_credit_exchange',
				width: '70px',
			},
		]
		const { reportOrderNumber } = storeOrder()
		const { result } = useSubscription(SubscribeOrderLinesSubscription, () => ({
			order_number: reportOrderNumber.value,
		}))
		const orderLinesArray = useResult(
			result,
			null,
			(data) => data.goods_transactions,
		)
		const { mutate: deleteOrderLine } = useMutation(
			DeleteOrderByOrderNumberMutation,
		)
		const onCellClick = (params) => {
			if (params.column.field === 'delete') {
				console.log(params.row)
				deleteOrderLine({
					id: params.row.goods_transaction_id,
				})
			}
		}
		const onRowDoubleClick = (params) => {
			console.log(params.row)
		}
		return {
			perPageProp,
			columnsList,
			onCellClick,
			onRowDoubleClick,
			deleteOrderLine,
			orderLinesArray,
		}
	},
}
</script>

<style scoped></style>
